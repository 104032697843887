import { FC, useState, useMemo } from 'react';
import classNames from 'classnames';
import {
  isBefore,
  addDays,
  addMinutes,
  subDays,
  startOfHour,
  endOfDay,
  startOfDay,
  subMinutes,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { MutationResult } from '@apollo/client';

import Button from 'core-components/NewButton';
import { Icon, Message } from 'core-components';
import ModalWindow, {
  ModalButtonsFooter,
  ModalTextFooter,
} from 'core-components/NewModalWindow/NewModalWindow';
import { JuniLogo } from 'components/brand-assets';
import { useGetAvailableAppointmentSlotsQuery } from 'generated/graphql';
import SpinnerV2 from 'components/SpinnerV2';
import { AcuityAppointment } from 'models';
import { MAX_DAYS_IN_ADVANCE_TO_SCHEDULE_MAKEUP } from 'constants/makeup_classes';
import { getStartOfDayInPacificTime } from 'utils/makeupClassExpirationUtils';
import TimeSlotPicker, { MaskedBlock } from './TimeSlotPicker';
import { LearnerClassSchedulerBaseProps } from '../types';

import { NUM_DAYS_TO_SHOW } from '../constants';
import {
  ACUITY_TYPE_DURATION,
  ACUITY_APPOINTMENT_DEFAULT_DURATION,
} from '../../../../constants/acuity';
import ClassCancellationModal from './ClassCancellationModal';

interface SingleClassSchedulerModalProps extends LearnerClassSchedulerBaseProps {
  onSubmit: (time: Date) => void;
  modalDescription: string;
  confirmationMessage: string;
  schedulingResults?: MutationResult;
  refreshParentState?: () => void;
  upcomingClasses?: AcuityAppointment[];
  studentId: string;
  appointmentDatetime?: string;
  acuityAppointmentId?: number;
  refreshUpcomingSessions?: () => void;
  showOptionToCancelClassForMakeup?: boolean;
  additionalClassExpirationDate: Date | null;
  isMakeupClass?: boolean;
}

const SingleClassSchedulerModal: FC<SingleClassSchedulerModalProps> = ({
  appointmentTypeID,
  timezone,
  onSubmit,
  instructorAcuityId,
  modalDescription,
  confirmationMessage,
  studentFirstName,
  onFinish,
  refreshParentState,
  isOpen,
  closeModal,
  schedulingResults,
  upcomingClasses,
  appointmentDatetime,
  studentId,
  additionalClassExpirationDate,
  isMakeupClass,
  // below variable are used for allow cancellation for makeup
  acuityAppointmentId,
  refreshUpcomingSessions,
  showOptionToCancelClassForMakeup,
}) => {
  const [startDate, setStartDate] = useState(startOfDay(addDays(new Date(), 1)));
  const [selectedDatetime, setSelectedDatetime] = useState<Date | null>(null);
  const [classDatetimeConfirmed, setClassDatetimeConfirmed] = useState(false);
  const [
    isCancellationConfirmationModalOpen,
    setIsCancellationConfirmationModalOpen,
  ] = useState(false);
  const { data, loading, error } = useGetAvailableAppointmentSlotsQuery({
    variables: {
      instructorAcuityId: `${instructorAcuityId}`,
      appointmentTypeId: appointmentTypeID,
      startDatetime: startOfHour(startDate).toString(),
      endDatetime: endOfDay(addDays(startDate, NUM_DAYS_TO_SHOW)).toString(),
    },
    skip: schedulingResults?.loading || selectedDatetime !== null,
  });
  // Callback for previous page button
  const previousAvailability = () => {
    setStartDate(subDays(startDate, NUM_DAYS_TO_SHOW));
  };

  // Callback for next page button
  const moreAvailability = () => {
    setStartDate(addDays(startDate, NUM_DAYS_TO_SHOW));
  };

  const unselectNewClassDatetime = () => {
    setSelectedDatetime(null);
  };
  // This is really sketchy.
  // May not even need the useMemo piece.
  // - June
  const sessionDuration =
    ACUITY_TYPE_DURATION[appointmentTypeID] || ACUITY_APPOINTMENT_DEFAULT_DURATION;
  const unavailabilityIntervals = useMemo(
    () =>
      upcomingClasses
        ? upcomingClasses.reduce((intervals, session) => {
            const duration =
              ACUITY_TYPE_DURATION[session.appointmentTypeID] ||
              ACUITY_APPOINTMENT_DEFAULT_DURATION;
            const start = new Date(session.datetime);
            return [
              ...intervals,
              {
                start: subMinutes(start, sessionDuration - 1),
                end: addMinutes(start, duration - 1),
                description: `Conflicts with existing ${
                  session.appointmentTypeData.displayName || 'Session'
                } for ${studentFirstName}`,
              },
            ];
          }, [] as MaskedBlock[])
        : [],
    [upcomingClasses, studentFirstName, sessionDuration],
  );
  const handleSubmit = async () => {
    if (selectedDatetime) {
      try {
        await onSubmit(selectedDatetime);
      } catch (e) {
        console.log(e);
      }
    }
    setClassDatetimeConfirmed(true);
    if (refreshParentState) {
      await refreshParentState();
    }
  };
  const finish = () => {
    if (onFinish) {
      onFinish();
    }
  };
  const previousAvailabilityDisabled = isBefore(
    subDays(startDate, NUM_DAYS_TO_SHOW),
    new Date(),
  );

  const CancelClassSection = (
    <div className="flex flex-row gap-2 justify-between">
      <div>
        <div className="text-sm text-j-dark-600 font-medium">
          Not able to reschedule right now?
        </div>
        <div className="text-xs text-j-dark-300">
          Canceling will automatically convert your class to a makeup session that
          you can schedule at any time within your instructor's availability.
        </div>
      </div>
      <Button
        variant="secondary"
        size="small"
        onClick={() => setIsCancellationConfirmationModalOpen(true)}
      >
        Cancel class
      </Button>
    </div>
  );

  const CancelClassFooter = <div className="flex">{CancelClassSection}</div>;

  const TimezoneFooter = (
    <ModalTextFooter
      title="Your Timezone"
      description={`${timezone} (${format(new Date(), 'zz', {
        timeZone: timezone,
      })})`}
    />
  );

  const ConfirmFooter = (
    <ModalButtonsFooter
      primary={
        <Button onClick={handleSubmit} disabled={schedulingResults?.loading}>
          <div className="font-medium">Confirm</div>
        </Button>
      }
      secondary={
        <Button variant="secondary" onClick={unselectNewClassDatetime}>
          <Icon.ChevronLeft />
          <div className="font-medium">Back</div>
        </Button>
      }
    />
  );

  const CompleteFooter = (
    <ModalButtonsFooter
      primary={
        <Button variant="secondary" onClick={finish}>
          <div className="font-medium">Go to Calendar</div>
        </Button>
      }
    />
  );

  let ModalFooter: JSX.Element;
  if (selectedDatetime) {
    if (classDatetimeConfirmed) {
      ModalFooter = CompleteFooter;
    } else {
      ModalFooter = ConfirmFooter;
    }
  } else if (
    showOptionToCancelClassForMakeup &&
    acuityAppointmentId &&
    appointmentDatetime &&
    refreshUpcomingSessions
  ) {
    ModalFooter = CancelClassFooter;
  } else {
    ModalFooter = TimezoneFooter;
  }

  const showPostTimePickerContent = selectedDatetime && !schedulingResults?.error;

  const now = new Date();
  const availableSlots = isMakeupClass
    ? data?.availableAppointmentSlots?.filter(availableSlot => {
        const availableSlotDatetime = new Date(availableSlot);
        const farthestSchedulableDatetimeInFuture = getStartOfDayInPacificTime(
          addDays(now, MAX_DAYS_IN_ADVANCE_TO_SCHEDULE_MAKEUP + 1),
        );
        if (!isBefore(availableSlotDatetime, farthestSchedulableDatetimeInFuture)) {
          // Allow users to schedule makeups up to a max of 30 days in advance, rounding up to the next midnight in Pacific Time
          return false;
        }
        if (!additionalClassExpirationDate) {
          return true;
        }
        // Only include slot times that are before the makeup class' expiration date
        return isBefore(availableSlotDatetime, additionalClassExpirationDate);
      })
    : data?.availableAppointmentSlots;

  if (
    isCancellationConfirmationModalOpen &&
    acuityAppointmentId &&
    appointmentDatetime &&
    refreshUpcomingSessions &&
    showOptionToCancelClassForMakeup
  ) {
    return (
      <ClassCancellationModal
        isCancelModalOpen={isCancellationConfirmationModalOpen}
        closeCancelModal={() => {
          closeModal();
          setIsCancellationConfirmationModalOpen(
            !isCancellationConfirmationModalOpen,
          );
        }}
        formattedApptDatetime={format(
          utcToZonedTime(appointmentDatetime, timezone),
          "EEEE, LLLL do 'at' h:mm a zz",
          {
            timeZone: timezone,
          },
        )}
        studentFirstName={studentFirstName}
        studentId={studentId}
        acuityAppointmentId={acuityAppointmentId}
        refreshUpcomingSessions={refreshUpcomingSessions}
      />
    );
  }

  return (
    <ModalWindow
      isOpen={isOpen}
      closeModal={closeModal}
      title={!classDatetimeConfirmed ? 'Schedule' : ''}
      description={!classDatetimeConfirmed ? modalDescription : ''}
      renderFooter={() => ModalFooter}
    >
      <div className="flex flex-col w-full">
        {/** Availability Calendar controls */}
        {!selectedDatetime && (
          <div className="flex justify-between items-center mb-4">
            <div>
              <div className="text-j-dark-600 text-base font-medium">
                Pick a time
              </div>
              <div className="text-j-dark-300 text-sm">
                Your Timezone:{' '}
                {`${timezone} (${format(new Date(), 'zz', {
                  timeZone: timezone,
                })})`}
              </div>
            </div>

            <div className="flex">
              <div className="mr-3">
                <Button
                  onClick={previousAvailability}
                  disabled={previousAvailabilityDisabled}
                  variant="secondary"
                  icon
                >
                  <Icon.ArrowLeft />
                </Button>
              </div>
              <div className="mr-4">
                <Button onClick={moreAvailability} variant="secondary" icon>
                  <Icon.ArrowRight />
                </Button>
              </div>
            </div>
          </div>
        )}

        {showPostTimePickerContent && (
          <div className="w-full">
            {schedulingResults?.loading ? (
              <SpinnerV2 />
            ) : selectedDatetime && !classDatetimeConfirmed ? (
              <div className="font-graphik">
                <div className="text-j-dark-300 text-sm mb-4">
                  {confirmationMessage}
                </div>
                <div className="text-j-dark-600 font-medium text-base mb-4">
                  {format(
                    utcToZonedTime(selectedDatetime, timezone),
                    "EEEE, MMMM do 'at' h:mm a zzz",
                    {
                      timeZone: timezone,
                    },
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center p-4">
                <div className="py-10 px-7 rounded-full bg-j-dark-100 mb-7">
                  <JuniLogo size="md" />
                </div>
                {selectedDatetime && (
                  <>
                    <div className="text-j-dark-600 text-base font-medium mb-3">
                      Class Scheduled!{' '}
                    </div>
                    <div className="text-j-dark-300 text-sm mb-3">
                      You've scheduled a session for {studentFirstName} on:
                    </div>
                    <Message status="success">
                      {format(
                        utcToZonedTime(selectedDatetime, timezone),
                        "EEEE, LLLL do 'at' h:mm a zz",
                        {
                          timeZone: timezone,
                        },
                      )}
                    </Message>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {/** Availability table */}
        {!selectedDatetime && (
          <div className={classNames('flex flex-col w-full overflow-x-auto')}>
            {/** Container for table header */}
            {/* Table body */}
            {!loading ? (
              !error && !schedulingResults?.error ? (
                <TimeSlotPicker
                  availableSlots={availableSlots}
                  timezone={timezone}
                  setSelectedDatetime={setSelectedDatetime}
                  windowStart={startDate}
                  windowEnd={endOfDay(addDays(startDate, NUM_DAYS_TO_SHOW))}
                  maskedBlocks={unavailabilityIntervals}
                />
              ) : (
                <div className="p-5">
                  {!error && !schedulingResults?.error ? (
                    <Message status="info">
                      {' '}
                      No available times for these dates.
                    </Message>
                  ) : (
                    <Message status="error">
                      {' '}
                      {schedulingResults?.error ? (
                        <>{schedulingResults?.error.message}</>
                      ) : (
                        <>Unable to fetch availabilities at this time.</>
                      )}
                    </Message>
                  )}
                </div>
              )
            ) : (
              <SpinnerV2 size={48} />
            )}
          </div>
        )}
      </div>
    </ModalWindow>
  );
};

export default SingleClassSchedulerModal;
