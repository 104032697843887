import React, { FC } from 'react';
import moment from 'moment';
import { AcuityAppointment } from 'models';

import {
  ACUITY_APPOINTMENT_TYPES,
  ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS,
  ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
} from 'constants/acuity';
import { getLearnerSessionActionType } from 'utils/acuity';

import JoinSessionButton from 'components/JoinSessionButton';

import classNames from 'classnames';
import Button from 'core-components/NewButton';
import { Message } from 'core-components';
import { subMilliseconds, isBefore, subWeeks } from 'date-fns';
import { Tooltip } from 'components/ui';
import { MAX_WEEKS_IN_ADVANCE_FOR_PARENT_RESCHEDULING } from 'constants/rescheduling';
import { formatToDefaultDatetimeWithTimezone } from 'utils/dates';
import { getMakeupClassExpirationDate } from 'utils/makeupClassExpirationUtils';
import { ScheduleButtonContainer } from './styles';
import { ScheduleCardRowProps, isAdditionalClass, isClassPack } from './types';
import SchedulePanel from './SchedulePanel';

const DATE_FORMAT = 'ddd, MMM DD [at] h:mm a z';

interface FlexProps {
  className?: string;
  children?: React.ReactNode;
}

const Flex: FC<FlexProps> = ({ children, className }) => (
  <div className={classNames('flex flex-col sm:flex-row', className)}>
    {children}
  </div>
);

export const ScheduleCardRow = (props: ScheduleCardRowProps) => {
  const {
    type,
    session,
    student,
    parent,
    instructorName,
    onClickSchedule,
    onClickCancelImmediateUpcomingClass,
  } = props;

  const getSessionActionItem = (
    actionType: string | null,
    session: AcuityAppointment,
  ) => {
    const acuityAppt = session;
    switch (actionType) {
      case 'group-upcoming':
        return (
          <Message status="info">
            Contact us to reschedule semi-private classes!
          </Message>
        );
      case 'upcoming':
        return isBefore(
          new Date(),
          subWeeks(
            new Date(acuityAppt.datetime),
            MAX_WEEKS_IN_ADVANCE_FOR_PARENT_RESCHEDULING,
          ),
        ) ? (
          <Tooltip
            content={`Only classes in the next ${MAX_WEEKS_IN_ADVANCE_FOR_PARENT_RESCHEDULING} weeks can be rescheduled. To reschedule this class, please come back at a later date.`}
          >
            <Button
              variant="secondary"
              onClick={() => onClickSchedule && onClickSchedule(acuityAppt.id)}
              disabled
            >
              <div className="font-medium">Reschedule</div>
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(acuityAppt.id)}
          >
            <div className="font-medium">Reschedule</div>
          </Button>
        );
      case 'upcoming-imminent':
        return (
          <div className="flex md:flex-row flex-col gap-2">
            <Message status="warning">Your class is coming up!</Message>
            <Button
              variant="secondary"
              onClick={() =>
                onClickCancelImmediateUpcomingClass &&
                onClickCancelImmediateUpcomingClass(acuityAppt.id)
              }
            >
              <div className="font-medium">Cancel</div>
            </Button>
          </div>
        );
      case 'joinable':
        return (
          <ScheduleButtonContainer>
            <JoinSessionButton
              session={acuityAppt}
              isTrial={ACUITY_TRIAL_CLASS_APPOINTMENT_TYPE_IDS.includes(
                acuityAppt.appointmentTypeID,
              )}
            />
          </ScheduleButtonContainer>
        );
      default:
        return null;
    }
  };

  let header: React.ReactNode;
  let subheader: React.ReactNode;
  let actionItem: React.ReactNode;

  switch (type) {
    case 'upcoming':
      if (student && parent) {
        const acuityAppt = session as AcuityAppointment;
        const classType = acuityAppt?.appointmentTypeData.displayName;
        const classDatetime = formatToDefaultDatetimeWithTimezone(
          acuityAppt?.datetime,
          acuityAppt.timezone,
        );

        header = (
          <div className={classNames('text-j-dark-600 font-medium', 'mb-2')}>
            {classDatetime}
          </div>
        );
        subheader = (
          <Flex>
            <div className="text-j-dark-600">{classType}</div>{' '}
            <div className="text-j-dark-300 sm:ml-1">{`with ${instructorName}`}</div>
          </Flex>
        );

        const sessionActionType = getLearnerSessionActionType(
          acuityAppt,
          student?.firstName,
          parent?.hasMultipleChildren,
        );
        const sessionActionButton =
          [
            ...ACUITY_MARKETPLACE_BOOTCAMP_APPT_TYPES,
            ACUITY_APPOINTMENT_TYPES.COMPUTER_SCIENCE_CAMP_SESSION,
          ].includes(acuityAppt.appointmentTypeID) &&
          sessionActionType !== 'joinable'
            ? null
            : getSessionActionItem(sessionActionType, acuityAppt);
        actionItem = sessionActionButton;
      }
      break;
    case 'makeup':
      if (isAdditionalClass(session!)) {
        const classType = session.appointmentTypeData.displayName;
        const prevClassDate = moment(session?.originalClassDatetime)
          .tz(session?.originalClassTimezone)
          .format(DATE_FORMAT);
        const expirationDate = getMakeupClassExpirationDate(session);
        // we subtract 1ms so the date shows up as the actual expiration date, just before midnight, rather than the following date at 12am
        const formattedExpirationDate = expirationDate
          ? moment(subMilliseconds(expirationDate, 1))
              .tz(session?.originalClassTimezone)
              .format(DATE_FORMAT)
          : '';
        header = (
          <Flex>
            <div className="text-j-dark-600">{classType}</div>
            <div className="sm:ml-1 text-j-dark-300">{`with ${instructorName}`}</div>
          </Flex>
        );
        subheader = (
          <>
            <div className="flex flex-col sm:flex-row">
              <div className="text-j-dark-600">Prev Class Date:</div>
              <div className="sm:ml-1 text-j-dark-300">{prevClassDate}</div>
            </div>
            {formattedExpirationDate ? (
              <div className="flex flex-col sm:flex-row">
                <div className="text-j-dark-600">Expiration Date:</div>
                <div className="sm:ml-1 text-j-dark-300">
                  {formattedExpirationDate}
                </div>
              </div>
            ) : null}
          </>
        );
        actionItem = (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(session._id)}
            fullWidth
          >
            <div className="font-medium">Schedule</div>
          </Button>
        );
      }
      break;
    case 'class_pack':
      if (isClassPack(session!)) {
        const classType = session[0].appointmentTypeData.displayName;
        header = <div className="text-j-dark-600">{classType}</div>;
        subheader = (
          <div className="text-j-dark-300">{`${session.length} available`}</div>
        );
        actionItem = (
          <Button
            variant="secondary"
            onClick={() => onClickSchedule && onClickSchedule(session[0]._id)}
            fullWidth
          >
            <div className="font-medium">Schedule</div>
          </Button>
        );
      }
      break;
    default:
      break;
  }
  return (
    <SchedulePanel
      className="mb-3 last:mb-0"
      renderHeader={() => header}
      renderSubheader={() => subheader}
      renderActionItem={() => actionItem}
    />
  );
};

export default ScheduleCardRow;
