import PortalSection from 'components/PortalSection';
import { Student } from 'generated/graphql';
import { NavLink } from 'react-router-dom';
import SpaceShuttle from 'components/clubs/MyClubsZeroState/assets/space-shuttle.png';
import { MediaCard } from 'core-components';

const Announcements = ({ student }: { student: Student }) => {
  // Leaving this commented out as a TODO for generic announcements once admin UI is done

  // const {
  //   data: announcementsData,
  //   loading: announcementsLoading,
  // } = useGetAnnouncementsQuery();
  // const announcements = announcementsData?.queryAnnouncements?.items || [];

  const challengesUrl = `/learner/${student._id}/challenges_home`;
  return (
    <PortalSection name="Announcements">
      <div className="mb-5">
        <NavLink className="no-underline" to={challengesUrl}>
          <MediaCard>
            <MediaCard.Image src={SpaceShuttle} height="1/4" alt="People together" />
            <MediaCard.Content>
              <div className="text-j-dark-600 font-graphik font-medium text-sm leading-relaxed">
                Explore Open Challenges
              </div>
              <div className="text-j-dark-400 font-graphik text-sm leading-normal mt-2">
                Check out ongoing competitions and past winners!
              </div>
            </MediaCard.Content>
          </MediaCard>
        </NavLink>
      </div>
    </PortalSection>
  );
};

export default Announcements;
