import React, { useMemo } from 'react';
import styled from 'styled-components';
import { tailwindBreakpointSizes } from 'theme/breakpoints';
import PublicPage, {
  PublicPageError,
  PublicPageLoading,
} from 'app/learner/PublicPage';
import {
  ICourse,
  useGetJuniverseUserProfileByUsernameQuery,
} from 'generated/graphql';
import { pick, orderBy } from 'lodash';
import achievementsEmptyStateIcon from 'images/certificate-zero-state.png';
import { mongoIDToDate } from 'utils/timeUtils';
import Header from './Header';
import SidebarCard from './SidebarCard';
import CompletedCourse from './CompletedCourse';
import Achievement from './Achievement';
import Projects from './Projects';

// keep in sync with BE Apollo error
const isProfileNotFoundError = (error: Error) =>
  error?.message === 'No Juniverse User Found';

const SidebarCardContainer = styled.div`
  width: 100%;

  @media (min-width: ${tailwindBreakpointSizes.lg}px) {
    width: 416px;
  }
`;

interface JuniverseUserProfileProps {
  username: string;
  activeStudentId?: string;
  showLoadingState?: boolean;
}

const JuniverseUserProfile: React.FC<JuniverseUserProfileProps> = ({
  username,
  activeStudentId,
  showLoadingState = true,
}) => {
  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useGetJuniverseUserProfileByUsernameQuery({
    variables: { username },
  });
  const loading = profileLoading;
  const error = profileError;
  const profile = profileData?.juniverseUserProfileByUsername;
  const featuredProjects = useMemo(
    () =>
      orderBy(
        profile?.featuredProjects || [],
        p => mongoIDToDate(p._id).getTime(),
        'desc',
      ),
    [profile?.featuredProjects],
  );
  const publicProjects = useMemo(
    () =>
      orderBy(
        profile?.publicProjects || [],
        p => mongoIDToDate(p._id).getTime(),
        'desc',
      ),
    [profile?.publicProjects],
  );

  if (loading) {
    return showLoadingState ? <PublicPageLoading /> : null;
  }
  if (!!error || !profile) {
    const props =
      !!error && isProfileNotFoundError(error)
        ? {
            errorMessage: 'Profile Not Found!',
            errorDescription: '',
          }
        : {};
    return <PublicPageError {...props} />;
  }

  const completedCourses = (profile.courseProgresses || [])
    .filter(c => c.isComplete)
    .map(c => ({ ...c.course }));
  const achievements = profile.achievements || [];
  const headerProfileProps = pick(profile, [
    '_id',
    'firstName',
    'lastInitial',
    'type',
    'username',
    'projectViewsAllTime',
    'projectsPublishedAllTime',
    'isGuest',
    'createdAt',
  ]);
  const showCompletedCourses = profile.type === 'STUDENT';
  const showAchievements = profile.type === 'INSTRUCTOR';
  const isActiveStudent =
    profile.type === 'STUDENT' && activeStudentId === profile._id;

  return (
    <PublicPage>
      {!activeStudentId && <PublicPage.NavBar />}
      <PublicPage.MainContent>
        <Header {...headerProfileProps} isActiveStudent={isActiveStudent} />
        <div className="w-full flex justify-center">
          <div className="w-full flex flex-col lg:flex-row my-6 mx-6 xl:mx-18 max-w-screen-xl">
            {showCompletedCourses || showAchievements ? (
              <SidebarCardContainer>
                <SidebarCard>
                  {showCompletedCourses && (
                    <SidebarCard.Section<ICourse>
                      title="Completed Courses"
                      items={completedCourses}
                      itemKey={course => course._id}
                      emptyStateMessage={`${
                        isActiveStudent ? 'Your' : `${profile.firstName}'s`
                      } courses will appear here when complete.`}
                      emptyStateIconUrl={achievementsEmptyStateIcon}
                      emptyStateCtaButton={
                        isActiveStudent
                          ? {
                              href: `/learner/${activeStudentId}/roadmap`,
                              text: 'View courses',
                            }
                          : undefined
                      }
                      renderItem={course => <CompletedCourse course={course} />}
                    />
                  )}
                  {showAchievements && (
                    <SidebarCard.Section<
                      React.ComponentProps<typeof Achievement>['achievement']
                    >
                      title="Achievements"
                      items={achievements}
                      itemKey={achievement => achievement.name}
                      emptyStateMessage={`${profile.firstName} does not have achievements yet.`}
                      emptyStateIconUrl={achievementsEmptyStateIcon}
                      renderItem={achievement => (
                        <Achievement achievement={achievement} />
                      )}
                    />
                  )}
                </SidebarCard>
              </SidebarCardContainer>
            ) : null}
            <div className="mt-6 lg:mt-0 lg:ml-6 lg:flex-1">
              <Projects
                featuredProjects={featuredProjects}
                projects={publicProjects}
                userFirstName={profile.firstName || ''}
                isActiveStudent={isActiveStudent}
                studentId={activeStudentId}
              />
            </div>
          </div>
        </div>
      </PublicPage.MainContent>
      {!activeStudentId && <PublicPage.JuniverseFooter />}
    </PublicPage>
  );
};

export default JuniverseUserProfile;
