import React from 'react';
import classNames from 'classnames';
import PortalSection from 'components/PortalSection';
import {
  StudentProject,
  useGetFeaturedProjectsQuery,
  StudentProjectFeaturedType,
} from 'generated/graphql';

import RingSpinner from 'components/RingSpinner';
import JuniverseProjectCard from 'app/miscellaneous/JuniverseProjectCard';

const HomepageMainFeed = () => {
  // Get Featured Projects
  const { data, loading: isLoadingProjects } = useGetFeaturedProjectsQuery({
    variables: { featuredType: StudentProjectFeaturedType.MyFeed },
  });

  const featuredStudentProjects = data?.featuredStudentProjects?.items || [];
  const juniverseProjects = featuredStudentProjects
    .filter((p): p is StudentProject => !!p)
    .sort((a, b) => (a._id < b._id ? 1 : -1));

  const projectCards = juniverseProjects.map((juniverseProject, index) => (
    <div
      key={juniverseProject._id.toString()}
      className={classNames({ 'mt-6': index > 0 })}
    >
      <JuniverseProjectCard {...juniverseProject} />
    </div>
  ));

  return (
    <>
      <PortalSection name="Juniverse Project Feed">
        {isLoadingProjects ? <RingSpinner /> : <> {projectCards}</>}
      </PortalSection>
    </>
  );
};

export default HomepageMainFeed;
