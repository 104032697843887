// keep these in sync with constants/makeup_classes.ts on the juni-app-backend repo

import { addDays, subMilliseconds } from 'date-fns';

export const DEFAULT_MAKEUP_CLASS_DAYS_TO_EXPIRATION_AFTER_CREATION = 90;
export const MAKEUP_CLASS_EXPIRATION_ADDITIONAL_GRACE_PERIOD_DAYS = 30;
export const MAX_DAYS_IN_ADVANCE_TO_SCHEDULE_MAKEUP = 30;

// 12am (midnight) PT on July 15, 2024
export const MIN_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY = new Date(
  '2024-07-15T07:00:00.000Z',
);

// 1 millisecond before 11am PT on October 15, 2024
export const MAX_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY = subMilliseconds(
  new Date('2024-10-15T18:00:00.000Z'),
  1,
);

// 12am (midnight) PT on October 16, 2024
const oct16StartOfDayPT = new Date('2024-10-16T07:00:00.000Z');

// 12am (midnight) PT on November 15, 2024
export const MIN_EXPIRATION_DATE_FOR_MAKEUPS_ELIGIBLE_FOR_GRACE_PERIOD = addDays(
  oct16StartOfDayPT,
  MAKEUP_CLASS_EXPIRATION_ADDITIONAL_GRACE_PERIOD_DAYS,
);
