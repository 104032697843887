// keep this file in sync with utils/makeupClassExpirationUtils.ts on the juni-app-backend repo

import { isBefore, isAfter, addDays, startOfDay } from 'date-fns';
import {
  DEFAULT_MAKEUP_CLASS_DAYS_TO_EXPIRATION_AFTER_CREATION,
  MAKEUP_CLASS_EXPIRATION_ADDITIONAL_GRACE_PERIOD_DAYS,
  MIN_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY,
  MAX_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY,
  MIN_EXPIRATION_DATE_FOR_MAKEUPS_ELIGIBLE_FOR_GRACE_PERIOD,
} from 'constants/makeup_classes';

import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

interface AdditionalClass {
  _id: string;
  parentId: string;
  studentId: string;
  customerLogId: string;
  originalClassId: string;
  appointmentTypeID: number;
  originalClassDatetime: string;
  originalClassTimezone: string;
  originalClassAcuityInstructorId: string;
  originalClassInstructorName: string;
  originalClassCanceled: boolean;
  isRedeemable: boolean;
  newClassDatetime: string;
  redeemedCustomerLogId: string;
  creationTimestamp: string;
  isMakeupClass: boolean;
  sessionLogDeleted: boolean;
}

export const getStartOfDayInPacificTime = (date: Date) => {
  const pacificTimeZone = 'America/Los_Angeles';
  const zonedDate = utcToZonedTime(date, pacificTimeZone);
  const startOfDayInPacific = startOfDay(zonedDate);
  // Convert back to a UTC-based Date object that reflects midnight in Pacific Time
  const result = zonedTimeToUtc(startOfDayInPacific, pacificTimeZone);
  return result; // This Date object now represents Pacific midnight in UTC form
};

const isMakeupEligibleForGracePeriod: (
  additionalClass: AdditionalClass,
) => boolean = additionalClass => {
  if (!additionalClass.isMakeupClass) {
    return false;
  }
  const makeupCreationDate = new Date(additionalClass.creationTimestamp);
  if (
    isBefore(
      makeupCreationDate,
      MIN_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY,
    ) ||
    isAfter(
      makeupCreationDate,
      MAX_MAKEUP_CREATION_DATE_FOR_GRACE_PERIOD_ELIGIBILITY,
    )
  ) {
    return false;
  }
  return true;
};

export const getMakeupClassExpirationDate: (
  additionalClass: AdditionalClass,
) => Date | null = additionalClass => {
  if (!additionalClass.isMakeupClass) {
    // we're not setting/enforcing an expiration date for class pack classes (yet)
    return null;
  }
  const isEligibleForGracePeriod = isMakeupEligibleForGracePeriod(additionalClass);
  const makeupCreationDate = new Date(additionalClass.creationTimestamp);
  if (!isEligibleForGracePeriod) {
    // Ensure expiration date is rounded up to next midnight in Pacific Time by adding
    // one extra day and shifting the time back to the start of that next day in PT
    return getStartOfDayInPacificTime(
      addDays(
        makeupCreationDate,
        DEFAULT_MAKEUP_CLASS_DAYS_TO_EXPIRATION_AFTER_CREATION + 1,
      ),
    );
  }
  const expirationDate = getStartOfDayInPacificTime(
    addDays(
      makeupCreationDate,
      DEFAULT_MAKEUP_CLASS_DAYS_TO_EXPIRATION_AFTER_CREATION +
        MAKEUP_CLASS_EXPIRATION_ADDITIONAL_GRACE_PERIOD_DAYS +
        1,
    ),
  );
  return isBefore(
    expirationDate,
    MIN_EXPIRATION_DATE_FOR_MAKEUPS_ELIGIBLE_FOR_GRACE_PERIOD,
  )
    ? MIN_EXPIRATION_DATE_FOR_MAKEUPS_ELIGIBLE_FOR_GRACE_PERIOD
    : expirationDate;
};

export const isMakeupClassExpired: (additionalClass: AdditionalClass) => boolean = (
  additionalClass: AdditionalClass,
) => {
  const expirationDate = getMakeupClassExpirationDate(additionalClass);
  if (!expirationDate) {
    return false;
  }
  const now = new Date();
  return !isBefore(now, expirationDate);
};
