import SpinnerV2 from 'components/SpinnerV2';
import { DatePickerField } from 'components/ui';
import { ASYNC_PLAN_PRODUCT } from 'constants/subscription_plans';
import { Input, Message, NewButton, NewCard, Select, Icon } from 'core-components';
import { addDays, addSeconds, isBefore } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useCancelStripeSubscriptionMutation } from 'generated/graphql';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getParentsUpcomingSessions } from 'services/learner/sessions';
import styled from 'styled-components';
import { isStudentIdInAcuityAppt, isStudentNameInAcuityAppt } from 'utils/acuity';
import { getSubscriptionDisplayName, hasAsync } from 'utils/stripe';
import { getStudentNamesFromStudents } from 'app/learner/LearnerAccountV2/utils';
import LearnerPortalModal from '../LearnerPortalModal';
import { CheckboxWrapper, ModalCheckbox } from '../styles';
import { CANCELLATION_REASONS, CANCELLATION_REASONS_OPTIONS } from './constants';

const STOP_IMMEDIATELY = 'stop_immediately';

const DatePickerContainer = styled.div`
  display: flex;
  flex: 1 0;
  align-items: flex-end;
  margin-right: 1rem;
  width: 100%;
  .ui-field {
    flex: 1;
    width: max-content;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .ui-input {
    height: 2.5rem;
    background: #fff;
    border: 1px solid #cac9d8; //j-dark-200
    padding: 0 0 0 0.75rem;
    font-size: 0.87rem;
    line-height: 1.25rem;
    font-family: 'Graphik', sans-serif;
    color: '#292563'; //j-dark-600
  }
  .ui-input:hover {
    border: 1px solid #9492b1; //j-dark-300
  }
  .ui-input:focus {
    border: 1px solid #9492b1; //j-dark-300
    box-shadow: 0 0 0 2px #e2e1ed;
    padding-left: 0.75rem;
  }
  .ui-input:active {
    border: 1px solid #292563; //j-dark-600
    box-shadow: 0 0 0 2px #e2e1ed;
    padding-left: 0.75rem;
  }
  .react-datepicker__close-icon::after {
    background: #413e75; //j-dark-500
  }
`;

const SubscriptionInformationSection = ({
  productName,
  currentStudents,
  formValues,
}) => {
  const curStudentFirstName = getStudentNamesFromStudents(currentStudents);
  const { subscription } = formValues;
  const hasAsyncProduct = hasAsync(subscription);
  return (
    <div className="mb-2">
      <div className="mb-3">You're cancelling the following subscription(s):</div>
      <NewCard
        padding="0"
        shadow="none"
        className="border border-solid border-j-dark-100"
      >
        <div className="py-4 px-4 flex flex-col">
          <div className="font-semibold mb-1">Student Name(s)</div>
          <div className="text-j-dark-300">{curStudentFirstName}</div>
        </div>
        <NewCard.Divider />
        <div className="py-4 px-4 flex flex-col">
          <div className="font-semibold mb-1">Subscription Name</div>
          <div className="text-j-dark-300">{productName}</div>
          {hasAsyncProduct && (
            <div className="text-j-dark-300">{ASYNC_PLAN_PRODUCT.displayName}</div>
          )}
        </div>
        <NewCard.Divider />
        <div className="py-4 px-4 flex flex-col">
          <div className="font-semibold mb-1">Effective Cancellation Date</div>
          <div className="text-j-dark-300">
            {moment.unix(subscription.current_period_end).format('MMMM DD, YYYY')}
          </div>
        </div>
      </NewCard>
    </div>
  );
};

const FinalClassSection = ({
  updateFormValue,
  formValues,
  currentStudents,
  parent,
  finalSessionOptions,
  setFinalSessionOptions,
}) => {
  const [loadingFinalSessionOptions, setLoadingFinalSessionOptions] = useState(
    false,
  );

  const curStudentId = currentStudents.length ? currentStudents[0]._id : '';
  const curStudentFirstName = currentStudents.length
    ? currentStudents[0].firstName
    : '';
  const { parentHasMultipleChildren, email } = parent;
  const { finalSession, subscription } = formValues;

  const finalSessionSelectOptions = [
    {
      label: 'Stop classes immediately',
      value: STOP_IMMEDIATELY,
    },
    ...finalSessionOptions.map(session => {
      const zoned = utcToZonedTime(new Date(session.datetime), session.timezone);
      return {
        label: `${format(zoned, `h:mmaaa z 'on' EEEE MMM do, yyyy`, {
          timeZone: session.timezone,
        })}`,
        value: session.id,
      };
    }),
  ];

  const handleSelectFinalSession = (value, name) => {
    if (value === STOP_IMMEDIATELY) {
      updateFormValue(value, name);
      return;
    }
    const selectedFinalSession = finalSessionOptions.find(
      session => session.id === value,
    );
    updateFormValue(selectedFinalSession || '', name);
  };

  useEffect(() => {
    setFinalSessionOptions([]);
    if (!curStudentId || !email) {
      return;
    }
    setLoadingFinalSessionOptions(true);
    const fetchUpcomingSessionsForStudent = async () => {
      try {
        const allUpcomingSessions = await getParentsUpcomingSessions(email);
        const studentSessions = allUpcomingSessions.filter(
          session =>
            isStudentIdInAcuityAppt(
              session,
              curStudentId,
              parentHasMultipleChildren,
            ) ||
            isStudentNameInAcuityAppt(
              session,
              curStudentFirstName,
              parentHasMultipleChildren,
            ),
        );
        const cancellationDate = addSeconds(
          new Date(subscription.current_period_end * 1000),
          1,
        );
        const sessionsBeforeCancellationDate = studentSessions.filter(session =>
          isBefore(
            utcToZonedTime(new Date(session.datetime), session.timezone),
            cancellationDate,
          ),
        );
        const defaultFinalSession =
          sessionsBeforeCancellationDate && sessionsBeforeCancellationDate.length > 0
            ? sessionsBeforeCancellationDate[
                sessionsBeforeCancellationDate.length - 1
              ]
            : '';
        setFinalSessionOptions(studentSessions);
        updateFormValue(defaultFinalSession, 'finalSession');
        setLoadingFinalSessionOptions(false);
      } catch (err) {
        setLoadingFinalSessionOptions(false);
        console.log(err);
      }
    };
    fetchUpcomingSessionsForStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curStudentId, curStudentFirstName, email, parentHasMultipleChildren]);
  return (
    <div className="flex flex-col gap-4 mb-2">
      <div>Please select {curStudentFirstName}'s Final Session Date:</div>
      {loadingFinalSessionOptions ? (
        <SpinnerV2 size={32} />
      ) : (
        <Select
          fullWidth
          size="xsmall"
          selected={
            !finalSession
              ? ''
              : finalSession === STOP_IMMEDIATELY
              ? finalSession
              : finalSession.id
          }
          onChange={selected => handleSelectFinalSession(selected, 'finalSession')}
          placeholder="Select Final Session"
          options={finalSessionSelectOptions}
        />
      )}
      <Message status="info">
        Sessions scheduled between your Final Session Date and Effective Cancellation
        Date will be cancelled and credited as makeup sessions.
      </Message>
    </div>
  );
};

const CancelFeedbackModal = ({
  parent,
  formState,
  formValues,
  studentsQuery,
  subscriptionsQuery,
  paymentsQuery,
  updateFormState,
  updateFormValue,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [finalSessionOptions, setFinalSessionOptions] = useState([]);

  const {
    subscription,
    cancellationReasons,
    finalSession,
    followupDate,
    otherNotes,
  } = formValues;

  const productIds = subscription.items.data.map(
    subscriptionItem => subscriptionItem.plan.product,
  );
  const studentIsOnlyTakingAsyncCourses =
    productIds.length === 1 && hasAsync(subscription);

  const students = studentsQuery?.data?.studentsByParentIds?.items || [];
  const currentStudents = students.filter(student =>
    subscription?.metadata?.studentIds?.includes(student._id),
  );

  const toggleCheckbox = value => checked => {
    if (checked) {
      updateFormValue(
        [...formValues.cancellationReasons, value],
        'cancellationReasons',
      );
    } else {
      updateFormValue(
        formValues.cancellationReasons.filter(elem => elem !== value),
        'cancellationReasons',
      );
    }
  };

  const [cancelSubscription] = useCancelStripeSubscriptionMutation();

  const productName = getSubscriptionDisplayName(subscription);

  const handleCancelSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmitError('');
    const cancelSubscriptionInput = {
      subscriptionId: subscription.id,
      cancellationReasons,
      otherNotes,
    };
    if (followupDate) {
      cancelSubscriptionInput.followupDate = followupDate;
    }
    if (finalSession && finalSession !== STOP_IMMEDIATELY) {
      cancelSubscriptionInput.finalSessionDatetime = new Date(finalSession.datetime);
      cancelSubscriptionInput.finalSessionTimezone = finalSession.timezone;
    } else if (finalSessionOptions.length > 0) {
      // even if a customer requests to stop classes immediately (ie. doesn't select a final class datetime)
      // we still try to send up a timezone associated with any of their upcoming classes so we can format
      // dates accordingly in the automated email and customer log
      cancelSubscriptionInput.finalSessionTimezone = finalSessionOptions[0].timezone;
    }

    try {
      await cancelSubscription({
        variables: {
          input: cancelSubscriptionInput,
        },
      });
      await subscriptionsQuery.refetch();
      await paymentsQuery.refetch();

      const modalName = 'cancel_confirmation';
      updateFormState(modalName, 'modal');
    } catch (err) {
      setSubmitError(err.toString());
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LearnerPortalModal
      title="Pause or Cancel Subscription"
      updateFormState={updateFormState}
      formState={formState}
      renderPrimaryButton={
        <NewButton
          onClick={handleCancelSubmit}
          disabled={
            isSubmitting ||
            cancellationReasons.length === 0 ||
            (!studentIsOnlyTakingAsyncCourses && !finalSession) ||
            (cancellationReasons.includes('pausing_subscription') &&
              !followupDate) ||
            (cancellationReasons.includes('other') && !otherNotes)
          }
        >
          {isSubmitting ? <SpinnerV2 /> : <div className="font-medium">Submit</div>}
        </NewButton>
      }
      renderSecondaryButton={
        <NewButton
          onClick={() => {
            const modalName = 'cancel_optional_discount';
            updateFormState(modalName, 'modal');
          }}
          variant="secondary"
          renderIconLeft={props => <Icon.ChevronLeft {...props} />}
        >
          Back
        </NewButton>
      }
    >
      <div className="flex flex-col text-j-dark-600 w-full gap-2">
        <SubscriptionInformationSection
          productName={productName}
          currentStudents={currentStudents}
          formValues={formValues}
        />
        {!studentIsOnlyTakingAsyncCourses && (
          <FinalClassSection
            updateFormValue={updateFormValue}
            parent={parent}
            currentStudents={currentStudents}
            formValues={formValues}
            finalSessionOptions={finalSessionOptions}
            setFinalSessionOptions={setFinalSessionOptions}
          />
        )}
        <div>Please confirm your reason(s) for cancellation: (required)</div>
        <div className="grid grid-rows-4 grid-flow-col gap-1">
          {CANCELLATION_REASONS_OPTIONS.map(option => (
            <CheckboxWrapper>
              <ModalCheckbox
                dark
                spanStyle={{
                  border: '2px solid #292563',
                }}
                id={option.value}
                isActive
                isChecked={cancellationReasons.includes(option.value)}
                onChange={checked => {
                  if (cancellationReasons.includes(CANCELLATION_REASONS.other)) {
                    updateFormValue('', 'otherNotes');
                  }
                  toggleCheckbox(option.value)(checked);
                }}
                size="large"
              />
              <div className="flex items-center">{option.label}</div>
            </CheckboxWrapper>
          ))}
        </div>

        {cancellationReasons.includes(CANCELLATION_REASONS.pausingSubscription) && (
          <div className="mb-4">
            <div className="mb-2">What date should we reach back out to you?</div>
            <DatePickerContainer className="flex flex-row">
              <DatePickerField
                css={`
                  input {
                    height: 42px;
                    color: #25546d;
                    font-size: 13px;
                    ::placeholder {
                      color: #757575;
                    }
                  }
                `}
                className="full-width"
                dateFormat="MM/DD/YY"
                value={followupDate ? moment(followupDate) : null}
                name="followupDate"
                minDate={moment(addDays(new Date(), 1))}
                onDateChange={newDate => {
                  if (!newDate || newDate < moment(new Date())) {
                    updateFormValue(undefined, 'followupDate');
                  } else {
                    updateFormValue(newDate.format('MM/DD/YY'), 'followupDate');
                  }
                }}
                placeholderText="mm/dd/yy"
                isClearable
              />
            </DatePickerContainer>
          </div>
        )}
        {cancellationReasons.includes(CANCELLATION_REASONS.other) && (
          <div className="mb-4">
            <div className="mb-2">
              We take all your feedback to heart and use it to improve our service.
              (required)
            </div>
            <Input
              fullWidth
              placeholder="Leave your feedback here..."
              value={otherNotes}
              onChange={event => updateFormValue(event.target.value, 'otherNotes')}
            />
          </div>
        )}
        {submitError && (
          <Message status="error">
            Error cancelling Stripe subscription. Please contact Juni HQ if this
            error persists.
          </Message>
        )}
      </div>
    </LearnerPortalModal>
  );
};

export default CancelFeedbackModal;
