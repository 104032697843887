import React from 'react';
import { useParams } from 'react-router-dom';

import { ErrorableLoading } from 'components/ui';
import { Student } from 'models';
import { Parent, useParentCornerLiveAnnouncementsQuery } from 'generated/graphql';
import {
  Announcements,
  KeyLinks,
  LearningAtAGlance,
  Instructors,
  Referral,
  MembershipPlanAndLearningPreferences,
} from './components';
import OnboardingHandoffRequestCard from './components/RequestCard/OnboardingHandoffRequestCard';

interface Params {
  activeStudentId: string;
}

interface Props {
  parent: Parent;
  students: Student[];
  idLookup: any;
  isLoadingIdLookup: boolean;
}

const ParentCorner: React.FC<Props> = ({
  parent,
  students,
  idLookup,
  isLoadingIdLookup,
}) => {
  const { activeStudentId } = useParams<Params>();
  const student = students.find(s => s._id === activeStudentId);
  const { data: announcementsData } = useParentCornerLiveAnnouncementsQuery();
  const announcements = announcementsData?.parentCornerLiveAnnouncements.items;
  if (!student) return <ErrorableLoading error="Unable to find student" />;
  if (isLoadingIdLookup) return <ErrorableLoading />;

  return (
    <div className="flex flex-col gap-4 text-sm text-blue-gray-600 font-graphik">
      <div className="flex bg-j-dark-600">
        <div className="self-center max-w-screen-xl w-full box-border px-4 py-16 mx-auto my-auto">
          <div className="text-4xl font-medium text-j-gray-100">Parent Corner</div>
          {announcements && (
            <>
              <div className="py-4 text-base font-medium text-j-purple-400">
                Announcements
              </div>
              <Announcements data={announcements} />
            </>
          )}
        </div>
      </div>
      <div className="max-w-screen-xl box-border w-full mx-auto">
        <div className="flex flex-col md:flex-row p-4 gap-6">
          <div className="flex-shrink-0 w-auto md:w-80 flex flex-col gap-6">
            <KeyLinks student={student} />
            <Referral student={student} />
          </div>
          <div className="flex flex-col gap-6">
            <LearningAtAGlance
              students={students}
              student={student}
              idLookup={idLookup}
            />
            <OnboardingHandoffRequestCard student={student} />
            <MembershipPlanAndLearningPreferences
              student={student}
              parent={parent}
            />
            <Instructors student={student} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentCorner;
