import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';

import { getUnlockedCourseDataForLoggedInParent } from 'services/courses';
import { getLoggedInParent } from 'services/learner/parent';
import {
  getModuleProgresses,
  getModuleSectionProgresses,
} from 'services/learner/progress';
import { somePathMatches } from 'utils/paths';
import { buildIdLookupTable } from 'utils/idLookup';
import {
  fetchPlaygroundProjectsForAllStudents,
  processPlaygroundProjects,
} from 'utils/queryPlaygroundProjects';
import JuniSpinner from 'components/JuniSpinner';
import PageError from 'components/ui/PageError';

import Playground from 'app/playground';
import { MyClubs, MyClubsLoader, ClubPage } from 'app/clubs';
import { ParentHelpCenter, ParentCorner } from 'app/miscellaneous';

import MyHomepageV2 from 'app/learner/MyHomepageV2/MyHomepageV2';

import { NavV2 } from 'components/NavV2';

import UserContext from 'modules/UserContext';
import { Profile } from 'app/profiles/Profile';
import LearnerRoadmap from './LearnerRoadmap';

import SessionNotesHome from './SessionNotesHome';
import ChallengesHome from './ChallengesHome';
import CalendarHome from './CalendarHome';
import OnDemandLibrary from './OnDemandLibrary';

import LearnerAccountV2 from './LearnerAccountV2';
import ManageSubscription from './LearnerAccountV2/ManageSubscription/ManageSubscription';
import './learner_app.css';

const CompletedCourseCertificate = React.lazy(() =>
  import('./CompletedCourseCertificate'),
);

const MyCourses = React.lazy(() => import('./MyCourses'));
const ReferralHome = React.lazy(() => import('../miscellaneous/ReferralHome'));
const RescheduleSession = React.lazy(() => import('./RescheduleSession'));
const JuniverseUserProfile = React.lazy(() =>
  import('../miscellaneous/JuniverseUserProfile'),
);
const StudentProjectsHome = React.lazy(() =>
  import('../miscellaneous/StudentProjectsHome'),
);

// Paths that do not show any navbar
const NO_NAV_PATHS = [/\/learner\/.*\/course_certificate\/.*/];

// used to distinguish Learner routes that work with student id in the URL,
// vs other pages embedded into the learner app (may not have student id in the URL).
export const LEARNER_ROOT_PATH = '/learner';

export const LEARNER_SUB_ROUTES = [
  '/my_courses',
  '/my_math',
  '/my_english',
  '/my_investing',
  '/my_code',
  '/my_code_old',
  '/account',
  '/refer',
  '/playground',
  '/roadmap',
  '/on_demand',
  '/help',
  '/parent_corner',
  '/reschedule_session',
];

class LearnerApp extends Component {
  state = {
    students: [],
    parent: null,
    idLookup: {},
    isLoadingIdLookup: true,
    isLoadingParentAndStudents: true,
    moduleProgresses: {},
    isLoadingModuleProgresses: false,
    moduleProgressesError: false,
    moduleSectionProgresses: {},
    isLoadingModuleSectionProgresses: false,
    moduleSectionProgressesError: false,
  };

  reloadIdLookup = async () => {
    await this.loadIdLookup();
  };

  componentDidMount() {
    this._isMounted = true;
    this.getParentAndStudents()
      .then(async data => {
        this.correctStudentIdParam(data.students);
        this.setState({
          ...data,
          isLoadingParentAndStudents: false,
        });
        const activeStudentId = this.getActiveStudentId(this.props);
        if (activeStudentId) {
          this.getModuleSectionProgresses(activeStudentId);
          const activeStudent = data.students.find(s => s._id === activeStudentId);
          if (activeStudent) {
            this.getModuleProgresses(activeStudent);
          }
        }

        this.loadIdLookup();
      })
      .catch(err => {
        console.log(err.response || err);
        this.setState({
          isLoadingIdLookup: false,
          isLoadingParentAndStudents: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.state.students.length > 0) {
      this.correctStudentIdParam(this.state.students);
    }
    const prevActiveStudentId = this.getActiveStudentId(prevProps);
    const activeStudentId = this.getActiveStudentId(this.props);
    if (activeStudentId && activeStudentId !== prevActiveStudentId) {
      if (!this.state.moduleSectionProgresses[activeStudentId]) {
        this.getModuleSectionProgresses(activeStudentId);
      }
      if (!this.state.moduleProgresses[activeStudentId]) {
        const activeStudent = this.state.students.find(
          s => s._id === activeStudentId,
        );
        if (activeStudent) {
          this.getModuleProgresses(activeStudent);
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadIdLookup() {
    const { courses, jideStudents } = await getUnlockedCourseDataForLoggedInParent();
    const rawPlaygroundProjects = await fetchPlaygroundProjectsForAllStudents(
      jideStudents,
    );
    const playgroundProjects = processPlaygroundProjects(rawPlaygroundProjects);

    const idLookup = buildIdLookupTable(courses, jideStudents, playgroundProjects);
    this.setState({ idLookup, isLoadingIdLookup: false });
  }

  // validates the active student id is one of current students, called continually on updates
  correctStudentIdParam = students => {
    const activeStudentId = this.getActiveStudentId(this.props);
    if (!activeStudentId) {
      this.onSelectStudent(students[0]._id);
      return;
    }
    const matchingStudent = students.find(
      student => student._id === activeStudentId,
    );
    if (!matchingStudent) {
      this.onSelectStudent(students[0]._id);
    } else if (this.props.match.path === LEARNER_ROOT_PATH) {
      this.maybeUpdateLearnerUrl(activeStudentId);
    }
  };

  getParentAndStudents = () => getLoggedInParent(true);

  getModuleProgresses = student => {
    const { user } = this.context;
    if (this._isMounted && !user.isGuest) {
      this.setState({ isLoadingModuleProgresses: true }, () => {
        getModuleProgresses(student)
          .then(moduleProgresses => {
            if (this._isMounted) {
              this.setState(prevState => ({
                moduleProgresses: {
                  ...(prevState.moduleProgresses || {}),
                  [student._id]: moduleProgresses,
                },
                moduleProgressesError: false,
                isLoadingModuleProgresses: false,
              }));
            }
          })
          .catch(err => {
            console.error(err.response || err);
            if (this._isMounted) {
              this.setState({
                moduleProgressesError: true,
                isLoadingModuleProgresses: false,
              });
            }
          });
      });
    }
  };

  getModuleSectionProgresses = studentId => {
    const { user } = this.context;
    if (this._isMounted && !user.isGuest) {
      this.setState({ isLoadingModuleSectionProgresses: true }, () => {
        getModuleSectionProgresses(studentId)
          .then(moduleSectionProgresses => {
            if (this._isMounted) {
              this.setState(prevState => ({
                moduleSectionProgresses: {
                  ...(prevState.moduleSectionProgresses || {}),
                  [studentId]: moduleSectionProgresses,
                },
                moduleSectionProgressesError: false,
                isLoadingModuleSectionProgresses: false,
              }));
            }
          })
          .catch(err => {
            console.error(err.response || err);
            if (this._isMounted) {
              this.setState({
                moduleSectionProgressesError: true,
                isLoadingModuleSectionProgresses: false,
              });
            }
          });
      });
    }
  };

  getStudentIdFromUrl = props => {
    if (props.match.path !== LEARNER_ROOT_PATH) {
      // may or may not use studentId in the url,
      // in either case its a different structure than assumed below
      return null;
    }

    const learnerPath = props.match.path;
    const fullPath = props.location.pathname;
    const rest = fullPath.substring(learnerPath.length);
    if (rest.length === 0 || rest[0] !== '/') {
      return null;
    }
    const indexOfNextSlash = rest.indexOf('/', 1);
    const nextPathItem =
      indexOfNextSlash !== -1
        ? rest.substring(0, indexOfNextSlash)
        : rest.substring(0);
    const hasStudentId =
      !LEARNER_SUB_ROUTES.includes(nextPathItem) && nextPathItem !== '/';
    return hasStudentId ? nextPathItem.substring(1) : null;
  };

  // note that correctStudentIdParam runs after every update and checks the validity
  // of the studentId derived from url or local storage, as the user can modify both.
  getActiveStudentId = props =>
    this.getStudentIdFromUrl(props) ||
    window.localStorage.getItem('activeStudentId') ||
    null;

  maybeNavigate = newPathname => {
    if (this.props.location.pathname !== newPathname) {
      this.props.history.push(newPathname);
    }
  };

  /**
   * Builds up the /learner/... url with studentId, or swaps it on change.
   * This function is idempotent and ends up doing nothing if the url is already at the correct path,
   * so it doesn't hurt to call it here consistently to ensure the URL is correct.
   */
  maybeUpdateLearnerUrl = studentId => {
    // build the URL up with the studentId
    const learnerPath = this.props.match.path;
    const fullPath = this.props.location.pathname;
    const rest = fullPath.substring(learnerPath.length);
    if (rest.length === 0) {
      this.maybeNavigate(`${learnerPath}/${studentId}`);
      return;
    }
    if (rest[0] !== '/') {
      this.maybeNavigate(`${learnerPath}/${studentId}${rest}`);
      return;
    }

    const curStudentId = this.getActiveStudentId(this.props);
    if (curStudentId) {
      this.maybeNavigate(fullPath.replace(curStudentId, studentId));
    } else {
      this.maybeNavigate(`${learnerPath}/${studentId}${rest}`);
    }
  };

  onSelectStudent = studentId => {
    window.localStorage.setItem('activeStudentId', studentId);

    if (this.props.match.path === LEARNER_ROOT_PATH) {
      this.maybeUpdateLearnerUrl(studentId);
    } else {
      // if there is no student Id in the URL, need to force re-render,
      // where the active student can be picked up from local storage.
      this.forceUpdate();
    }
  };

  studentsWithActiveFirst = activeStudentId =>
    [
      this.state.students.find(student => student._id === activeStudentId),
      ...this.state.students.filter(student => student._id !== activeStudentId),
    ].filter(student => student != null);

  render() {
    const { user } = this.context;
    const { match, location } = this.props;
    const {
      parent,
      students,
      idLookup,
      isLoadingIdLookup,
      isLoadingParentAndStudents,
      moduleProgresses,
      isLoadingModuleProgresses,
      moduleSectionProgresses,
      isLoadingModuleSectionProgresses,
    } = this.state;
    const activeStudentId = this.getActiveStudentId(this.props);
    const orderedStudents = this.studentsWithActiveFirst(activeStudentId);

    if (isLoadingParentAndStudents) {
      return <JuniSpinner />;
    }

    if (!parent || students.length === 0) {
      return (
        <PageError>
          There was an error loading your Juni account. Please contact{' '}
          <a href="mailto:support@learnwithjuni.com">support@learnwithjuni.com</a>.
        </PageError>
      );
    }

    const navBarProps = {
      hideNavBar: this.props.hideNavBar,
      setHideNavBar: this.props.setHideNavBar,
    };

    const isClubsEnabled = false;

    return (
      <Suspense fallback={<JuniSpinner />}>
        {isClubsEnabled ? (
          <MyClubsLoader studentIdParam={orderedStudents[0]._id} />
        ) : null}

        {!somePathMatches(location.pathname, NO_NAV_PATHS) &&
          !this.props.hideNavBar && (
            <NavV2
              layout="learner"
              parent={parent}
              orderedStudents={orderedStudents}
              onSelectStudent={this.onSelectStudent}
              isClubsEnabled={isClubsEnabled}
              isGuest={user.isGuest}
              idLookup={idLookup}
              isLoadingIdLookup={isLoadingIdLookup}
            />
          )}

        <Switch>
          {/* Note: The first path to match is the Route that gets rendered. */}
          <Route
            path={`${match.path}/account`}
            render={props => <LearnerAccountV2 {...props} parent={parent} />}
          />
          <Route
            path={`${match.path}/:activeStudentId/help`}
            render={props => <ParentHelpCenter userType="parent" {...props} />}
          />
          {process.env.NODE_ENV !== 'production' && (
            <Route
              path={`${match.path}/:activeStudentId/profile`}
              render={() => (
                <Profile
                  username={orderedStudents[0]?.username}
                />
              )}
            />
          )}
          <Route
            path={`${match.path}/reschedule_session/:apptId`}
            render={() => <RescheduleSession parent={parent} students={students} />}
          />
          <Route
            path={`${match.path}/:activeStudentId/parent_corner`}
            render={props => (
              <ParentCorner
                {...props}
                students={students}
                parent={parent}
                idLookup={idLookup}
                isLoadingIdLookup={isLoadingIdLookup}
              />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/account`}
            render={props => <LearnerAccountV2 {...props} parent={parent} />}
          />
          <Route
            path={`${match.path}/subscription`}
            render={props => <ManageSubscription {...props} parent={parent} />}
          />
          <Route
            path={`${match.path}/:activeStudentId/subscription`}
            render={props => (
              <ManageSubscription
                {...props}
                parent={parent}
                student={orderedStudents[0]}
              />
            )}
          />

          <Route
            path={`${match.path}/${activeStudentId}/home`}
            render={() => (
              <MyHomepageV2
                parent={parent}
                students={orderedStudents}
                idLookup={idLookup}
                isLoadingIdLookup={isLoadingIdLookup}
                isGuest={user.isGuest}
              />
            )}
          />

          <Route
            path={`${match.path}/:activeStudentId/refer`}
            render={() => <ReferralHome userType="parent" />}
          />
          {/* todo(andrewito): deprecate CompletedCourseCertificate. Possible need to redirect to /learner/${student._id}/course_certificate */}
          <Route
            path={`${match.path}/:activeStudentId/course_certificate/:courseName`}
            render={props => (
              <CompletedCourseCertificate
                {...props}
                student={orderedStudents[0]}
                idLookup={idLookup}
                isLoadingIdLookup={isLoadingIdLookup}
                courseName={props.match.params.courseName}
              />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/on_demand/:subjectName?/:courseName?`}
            render={() => (
              <OnDemandLibrary
                studentId={orderedStudents[0]._id}
                parentId={parent._id}
              />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/roadmap/:subjectName?/:courseName?`}
            render={props => (
              <LearnerRoadmap
                roadmapLinkBase={`${match.path}/${activeStudentId}/roadmap`}
                subjectName={props.match.params.subjectName}
                courseName={props.match.params.courseName}
                student={orderedStudents[0]}
              />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/my_clubs`}
            render={() => (
              <Redirect to="/" />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/club_page/:currentClubId?/:currentChannelName?`}
            render={props => (
              <Redirect to="/" />
            )}
          />

          {user.isGuest ? null : (
            <Route
              path={`${match.path}/:activeStudentId/session_notes`}
              render={props => (
                <SessionNotesHome
                  {...props}
                  userId={user._id}
                  students={orderedStudents}
                  parent={parent}
                  idLookup={idLookup}
                  moduleSectionProgresses={moduleSectionProgresses[activeStudentId]}
                  isLoadingCourseData={
                    isLoadingIdLookup ||
                    isLoadingModuleProgresses ||
                    isLoadingModuleSectionProgresses
                  }
                />
              )}
            />
          )}

          {!user.isGuest && (
            <Route
              path={`${match.path}/:activeStudentId/calendar`}
              render={props => (
                <CalendarHome
                  {...props}
                  parent={parent}
                  student={orderedStudents[0]}
                />
              )}
            />
          )}

          <Route
            path={`${match.path}/:activeStudentId/challenges_home`}
            render={props => <ChallengesHome {...props} />}
          />

          <Route
            path={`${match.path}/:activeStudentId/playground`}
            render={props => (
              <Playground
                idLookup={idLookup}
                currentUser={{
                  ...orderedStudents[0],
                  activeStudentId,
                  userType: 'student',
                }}
                {...props}
                {...navBarProps}
              />
            )}
          />
          {/* Oct 2023: leaving these redirects here until we confirm 
              nothing else links to these paths */}
          <Route
            path={[
              `${match.path}/:activeStudentId/my_code`,
              `${match.path}/:activeStudentId/my_computer_science`,
              `${match.path}/:activeStudentId/my_math`,
              `${match.path}/:activeStudentId/my_mathematics`,
              `${match.path}/:activeStudentId/my_investing`,
              `${match.path}/:activeStudentId/my_science`,
            ]}
            render={() => (
              <Redirect
                to={location.pathname
                  .replace('/my_code', '/my_courses')
                  .replace('/my_computer_science', '/my_courses')
                  .replace('/my_math', '/my_courses')
                  .replace('/my_mathematics', '/my_courses')
                  .replace('/my_investing', '/my_courses')
                  .replace('/my_science', '/my_courses')}
              />
            )}
          />
          <Route
            path={`${match.path}/:activeStudentId/my_courses`}
            render={props => (
              <MyCourses
                {...props}
                student={orderedStudents[0]}
                idLookup={idLookup}
                isLoadingIdLookup={isLoadingIdLookup}
                reloadIdLookup={this.reloadIdLookup}
                isLoadingProgresses={
                  isLoadingModuleProgresses || isLoadingModuleSectionProgresses
                }
                moduleProgresses={moduleProgresses[activeStudentId]}
                moduleSectionProgresses={moduleSectionProgresses[activeStudentId]}
                {...navBarProps}
              />
            )}
          />
          <Route
            path="/juniverse/users/:username"
            render={props => (
              <JuniverseUserProfile
                username={decodeURIComponent(props.match.params.username)}
                activeStudentId={activeStudentId}
                showLoadingState={false}
              />
            )}
          />
          <Route
            path="/student-projects"
            render={() => <StudentProjectsHome activeStudentId={activeStudentId} />}
          />
          <Route
            path={`${match.path}/:activeStudentId`}
            render={() => <Redirect to={`${match.path}/${activeStudentId}/home`} />}
          />
        </Switch>
      </Suspense>
    );
  }
}
LearnerApp.contextType = UserContext;
LearnerApp.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default LearnerApp;
